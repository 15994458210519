.page-loader {
  z-index: 200;
  color: #fff;
  background: #141516;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.page-loader .fa {
  font-size: 2.5rem;
}

.page-loader, .page-loader.visible {
  opacity: 1;
  visibility: visible;
  transition: all 1.2s ease-in;
}

.page-loader.p-hidden {
  visibility: hidden;
  opacity: 0;
}

.page-loader div {
  text-align: center;
  width: 100%;
  margin-top: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.page-loader div .icon {
  content: "";
  background: none;
  border: 8px solid #0000;
  border-color: #0000 currentColor;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  display: inline-block;
  position: absolute;
  left: 50%;
}

.page-loader div p {
  color: currentColor;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  font-family: Inter, Montserrat, Roboto, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.icon-spin, .icon-loading-a {
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}
/*# sourceMappingURL=index.0e2b31e7.css.map */
